




































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { ColumnConfig } from '@/components/table/types'
@Component
export default class accountList extends Vue {
    loading = false;
    // 弹窗展示
    dialogTableVisable = false;
    withdrawVisable = false;
    inviteVisable = false;
    activitiesVisible = false;
    // 表格默认total
    total = 0;
    activitiesTotal=0;
    inviteTotal=0;
    withdrawTotal=0;
    freezeTotal = 0;
    moneyTotal=0
    communityData = [];
    current = 1;
    // 弹窗表格默认当前页
    withdrawCurrent = 1;
    activitiesCurrent = 1;
    inviteCurrent = 1;
    //  弹窗表格默认id
    inviteId=0
    activitiesId=0
    withdrawId=0

    // // 弹窗表格默认查询条数
    // invitePageSize=10
    // activitiesPageSize=10
    // withdrawPageSize=10

    // 查询头
    formInline = {
      addressId: '',
      status: '',
      nickName: '',
      phone: ''
    };

    // 活跃度表格
    activeTableData:Array<any> = [];
    // ta邀请的人
    inviteTableData = [];
    // 活动详情
    activitiesTableData = [];
    // 提现详情
    withdrawTableData = [];
    tableData = [];
    column: Array<ColumnConfig> = [
      {
        label: '用户名',
        code: 'nickName',
        scopedSlots: 'nickName',
        fixed: 'left'
      },
      {
        label: '手机号',
        code: 'phone',
        width: '180px'
      },
      {
        label: '所属圈子',
        code: 'address',
        width: '180px'
      },
      {
        label: '注册日期',
        code: 'insertAt',
        width: '180px'
      },
      {
        label: '关注圈子',
        code: 'attentionAddress',
        scopedSlots: 'attentionAddress',
        width: '180px'
      },
      {
        label: '最近活跃日期',
        code: 'updateAt',
        width: '180px'
      },
      {
        label: '状态',
        code: 'status',
        scopedSlots: 'status',
        width: '180px'
      },
      {
        label: '邀请TA的人',
        code: 'inviterName',
        width: '180px'
      },
      {
        label: 'TA邀请的人',
        code: 'status',
        scopedSlots: 'invite',
        width: '180px'
      },
      {
        label: '发布',
        code: 'publish',
        scopedSlots: 'publish',
        width: '180px'
      },
      {
        label: '活跃度',
        code: 'publish',
        scopedSlots: 'active',
        width: '180px'
      },
      {
        label: '提现金额',
        code: 'withdraw',
        scopedSlots: 'withdraw',
        width: '180px'
      },
      //   {
      //     label: '认证状态',
      //     code: 'authStatus',
      //     scopedSlots: 'authStatus',
      //     width: '180px'
      //   },
      //   {
      //     label: '认证资料',
      //     code: 'image',
      //     scopedSlots: 'image',
      //     width: '180px'
      //   },
      {
        label: '活动参与',
        code: 'activities',
        scopedSlots: 'activities',
        width: '180px'
      },
      {
        label: '操作',
        key: 'action',
        fixed: 'right',
        options: [
          {
            title: '冻结',
            click: (row: any) => {
              this.updateCommunity(row.id, 1)
            }
          },
          {
            title: '解冻',
            click: (row: any) => {
              this.updateCommunity(row.id, 0)
            }
          },
          {
            title: '删除',
            click: (row: any) => {
              this.updateCommunity(row.id, -1)
            }
          },
          {
            title: '重置签到',
            click: (row: any) => {
              this.onResetCheck(row.id)
            }
          }
        //   ,
        //   {
        //     title: '再次认证',
        //     click: (row: any) => {
        //       this.onResetAuthStatus(row.id)
        //     },
        //     isShow: (row:any) => {
        //       if (row.authStatus === 2) {
        //         return true
        //       } else {
        //         return false
        //       }
        //     }
        //   }
        ],
        width: '280px'
      }
    ];

    //   活跃度展示
    activColumn: Array<ColumnConfig> = [
      {
        label: '点赞',
        code: 'likeTotal'
      },
      {
        label: '发帖',
        code: 'publishTotal'
      },
      {
        label: '评论',
        code: 'commentTotal'
      },
      {
        label: '转发',
        code: 'reprintTotal'
      },
      {
        label: '被转次数',
        code: 'beReprintTotal'
      }
    ];

    // 提现列表
    withdrawColumn: Array<ColumnConfig> = [
      {
        label: '发放时间',
        code: 'issueTime'
      },
      {
        label: '发放金额/元',
        code: 'issueMoney'
      }
    ];

    // 查看参与活动
    activitiesColumn: Array<ColumnConfig> = [
      {
        label: '活动名称',
        code: 'activityName'
      },
      {
        label: '时间',
        code: 'engageTime'
      }
    ];

    // ta邀请的人
    inviteColumn: Array<ColumnConfig> = [
      {
        label: '时间',
        code: 'inviteTime'
      },
      {
        label: '名称',
        code: 'inviteeName'
      }
    ];

    ellipsisStr<T> (str: T, limit = 15): T | string {
      if (typeof str === 'string' && str && str.length > limit) {
        return str.substring(0, limit) + '...'
      } else {
        return str
      }
    }

    toMore (id: number) {
      // '/accountinfo',query:{id:id}
      this.$router.push({ path: '/accountinfo', query: { id: String(id) } })
    }

    // 前往发布详情
    toPublish (id: number) {
      this.$router.push({ path: '/publishInfo', query: { id: String(id) } })
    }

    onSearch (): void {
      this.fetch()
    }

    onReSet () {
      this.formInline = {
        addressId: '',
        status: '',
        nickName: '',
        phone: ''
      }
      this.fetch()
    }

    // 重置签到
    async onResetCheck (id: number) {
      await this.$Post(this.$Api.resetSign + '/' + id)
      this.fetch()
      this.$message({
        type: 'success',
        message: '重置签到成功!'
      })
    }

    // 再次认证
    async onResetAuthStatus (id: number) {
      await this.$Post(this.$Api.resetAuthStatus + '/' + id)
      this.fetch()
      this.$message({
        type: 'success',
        message: '认证成功!'
      })
    }

    //  查看活跃度
    async onShowDetail (id: number) {
      this.activeTableData = []
      this.dialogTableVisable = true
      const res = await this.$Get(this.$Api.activeDetail + '/' + id)
      this.activeTableData.push(res.data)
    }

    // 查看提现详情
    async onShowWithdraw (id: number) {
      this.withdrawVisable = true
      this.withdrawId = id
      const res = await this.$Get(this.$Api.withdrawalDetail, {
        id,
        current: this.withdrawCurrent,
        pageSize: 10
      })
      this.withdrawTableData = res.data.records
      this.withdrawTotal = res.data.total
      console.log(this.withdrawTotal)
    }

    // 查看活动详情
    async onShowActivities (id: number) {
      this.activitiesVisible = true
      this.activitiesId = id
      const res = await this.$Get(this.$Api.activityDetail, {
        id,
        current: this.activitiesCurrent,
        pageSize: 10
      })
      this.activitiesTableData = res.data.records
    }

    // 查看TA邀请的人
    async onInvite (id: number) {
      this.inviteVisable = true
      this.inviteId = id
      const res = await this.$Get(this.$Api.inviteDetail, {
        id,
        current: this.inviteCurrent,
        pageSize: 10
      })
      this.inviteTableData = res.data.records
    }

    async updateCommunity (id: number, type: number) {
      const res = await this.$Post(this.$Api.updateUser, {
        id,
        type
      })
      this.fetch()
    }

    async fetch () {
      this.loading = true
      // const r = await this.$Get(this.$Api.userListPage, {
      //   pageSize: 10,
      //   current: 1,
      //   ...this.formInline
      // })
      const [r1, r2] = await Promise.all([
        this.$Get(this.$Api.userListPage, {
          pageSize: 10,
          current: this.current,
          ...this.formInline
        }),
        this.$Get(this.$Api.getUserTotal)
      ])
      this.freezeTotal = r2.data.freezeTotal
      this.moneyTotal = r2.data.moneyTotal
      this.tableData = r1.data.records.map((el: any) => {
        const previewList:Array<any> = []
        previewList.push(el.authData)
        el.previewList = previewList
        return el
      })
      this.total = r1.data.total
      this.withdrawTotal = r1.data.total
      this.total = r1.data.total
      this.total = r1.data.total
      this.loading = false
    }

    async fetchCommunityData () {
      const res = await this.$Get(this.$Api.getCommunityList)
      this.communityData = res.data
    }

    changePagination (current: number) {
      this.current = current
      this.fetch()
    }

    inviteChangePagination (current: number) {
      this.inviteCurrent = current
      this.onInvite(this.inviteId)
    }

    withdrawChangePagination (current: number) {
      this.withdrawCurrent = current
      this.onShowWithdraw(this.withdrawId)
    }

    activitiesChangePagination (current: number) {
      this.activitiesCurrent = current
      this.onShowActivities(this.activitiesId)
    }

    mounted () {
      this.fetch()
      this.fetchCommunityData()
    }
}
